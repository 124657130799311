export default class UsuarioService {
  constructor(http) {
    this._http = http
  }

  async usuarioPossuiPermissaoGestao() {
    const { data } = await this._http.get(`/api/usuarios/possuiPermissaoGestao`)
    return data
  }
}
