<template>
  <div>
    <div :class="containerClass" @click="onWrapperClick">
      <div class="layout-sidebar" @click="onSidebarClick">
        <div class="layout-logo-wrapper">
          <router-link to="/" class="layout-logo-text">
            <span>Portal do Servidor</span>
          </router-link>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>

      <AppTopBar :class="topBarClass" @menu-toggle="onMenuToggle" />
      <div>
        <Dialog
          :visible="visible"
          modal
          header="Consentimento LGPD"
          :style="{ width: '80%', padding: '10px' }"
          :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
          <ConsentimentoLgpd></ConsentimentoLgpd>
          <template #footer>
            <Button
              label="Recusar"
              icon="pi pi-times"
              class="p-button-danger md:col-12"
              text
              @click="logout()" />
            <Button
              label="Aceitar"
              icon="pi pi-check"
              class="p-button-success md:col-12"
              autofocus
              @click="consentirLgpd()" />
          </template>
        </Dialog>
      </div>
      <div>
        <Dialog
          :visible.sync="showComunicado"
          modal
          :style="{ width: '50%' }"
          :closable="true">
          <template #header>
            <div class="flex align-items-center justify-content-center w-full">
              <label style="font-size: large; margin: 0%">COMUNICADO</label>
            </div>
          </template>
          <label style="text-align: justify; margin: 1%">
            O Governo do Estado através da Secretaria de Estado da Administração
            comunica que, os servidores estaduais que receberiam o Abono
            Salarial do PASEP de 2024, referente aos meses de: fevereiro, março,
            abril e maio passarão a receber a
            <b
              >partir do dia 15 de junho/2024 e os demais servidores seguirão o
              calendário publicado pelo Ministério do Trabalho e Emprego</b
            >, conforme comunicado através do expediente
            <b
              >OFICIO SEI Nº 33656/2024/MTE, da Superintendência Regional do
              Trabalho e Emprego, datado de 13 de maio de 2024.</b
            >
          </label>
          <template #footer>
            <Button
              label="Fechar"
              icon="pi pi-times"
              class="p-button-success md:col-12"
              text
              @click="fecharComunicado()" />
          </template>
        </Dialog>
      </div>
      <div class="layout-main-container">
        <div class="layout-main">
          <router-view />
        </div>
      </div>
      <transition name="layout-mask">
        <div
          v-if="mobileMenuActive"
          class="layout-mask p-component-overlay"></div>
      </transition>
    </div>
    <div class="noPrint">
      <div class="card-mobile">
        <div
          class="footer sm:sticky flex md:flex-row justify-content-center flex-column bottom-0 mt-5">
          <img
            class="w-22rem align-self-center mt-3"
            src="@/img/SEAD_branco.png" />
          <img
            class="w-8rem h-1.5rem align-self-center flex mt-2"
            src="@/img/logo-codata-branco.png" />

          <div class="flex justify-content-center text-light text-50">
            <span>Atendimento: (83) 3208-4490 </span>
          </div>
          <div class="text-center text-50 mt-2">Versão: {{ versao }}</div>
        </div>
      </div>
      <div class="card-desktop">
        <div class="footer sm:sticky flex flex-column bottom-0 mt-5">
          <div class="flex justify-content-center">
            <div class="p-col mt-3">
              <img
                class="w-8rem h-1.5rem align-self-center flex"
                src="@/img/logo-codata-branco.png" />
            </div>
            <div class="p-col">
              <img
                class="w-22rem align-self-center"
                src="@/img/SEAD_branco.png" />
            </div>
          </div>
          <div class="flex justify-content-center text-light text-50">
            Atendimento: {{ telefoneContato }}
          </div>
          <div class="flex justify-content-end text-50 mr-2 mb-2">
            Versão: {{ versao }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import AppTopbar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import { matriculaStore } from '@/stores/matricula'
import ServidorService from '@/service/ServidorService'
import MatriculaService from '@/service/MatriculaService'
import ConsentimentoLgpdService from '@/service/ConsentimentoLgpdService'
import UsuarioService from '@/service/UsuarioService'
import Config from '@/config'
import consentimentoLgpd from '@/components/shared/consentimentoLgpd/ConsentimentoLgpd'

export default {
  components: {
    AppTopBar: AppTopbar,
    AppMenu: AppMenu,
    ConsentimentoLgpd: consentimentoLgpd,
  },

  setup() {
    const store = new matriculaStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      mobileMenuActive: false,
      menu: [],
      servidor: {},
      saving: false,
      submitted: false,
      qtdConsentidoLgpd: 0,
      visible: false,
      isStaff: false,
      textoLGPD: `
          TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS DOS USUÁRIOS DA PLATAFORMA PBCONSIG DA CODATA – COMPANHIA DE PROCESSAMENTO DE DADOS DA PARAÍBA

          1. O que é a LGPD?

          A Lei Geral de Proteção de Dados (13.709/2018) tem como principal objetivo proteger os direitos fundamentais de liberdade, de privacidade e livre desenvolvimento da personalidade da pessoa natural.A LGPD estabelece normas para a coleta e o tratamento de dados pessoais, a fim de impedir vazamento de dados e assegurar a privacidade e a proteção deles.

          2. Base Legal e Finalidade do tratamento de dados pelo PBconsig:

          O Artigo 7º da Lei Geral de Proteção de Dados (LGPD) estabelece as hipóteses de bases legais para o tratamento de dados pessoais por uma empresa ou instituição, com o objetivo de proteger os direitos fundamentais de liberdade e privacidade do seu titular.
          O tratamento de dados pessoais somente poderá ser realizado nas seguintes hipóteses:
          I - Mediante o fornecimento de consentimento pelo titular;
          II - Para o cumprimento de obrigação legal ou regulatória pelo controlador;
          III - Pela administração pública, para o tratamento e uso compartilhado de dados necessários à execução de políticas públicas previstas em leis e regulamentos ou respaldadas em contratos, convênios ou instrumentos congêneres, observadas as disposições do Capítulo IV desta Lei;

          Com base nessa hipótese de Execução de políticas públicas. (Art. 7º, III, LGPD ou Art. 11, II, "b", LGPD), a CODATA pode tratar os dados dos titulares para atender rotinas de tratamento de dados com a finalidade de promover análise de crédito e gestão dos consignados contratados pelos servidores públicos junto às consignatárias devidamente credenciadas e autorizadas pela Secretaria de Estado da Administração (SEAD) e CODATA conforme decreto estadual de n° 41.993/2021.

          3. Consentimento

          Para que o titular dos dados possa dar o seu consentimento para o tratamento de suas informações pessoais, ele deve ser informado de maneira clara, objetiva e transparente sobre a finalidade da coleta das informações, por meio de um termo de consentimento.

          Este documento visa registrar de acordo com o Art. 5º, Inciso XII, da LGPD, que o consentimento é a “manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada”.

          Ao manifestar sua aceitação para com o presente termo, o Titular consente e concorda que a CODATA, CNPJ nº 09.189.499/0001-00, com sede no Centro Administrativo Estadual, Rua João da Mata, S/N, Jaguaribe - CEP: 58.015-020 - Fone: (83) 3208-4490 / (83) 98658-8328 – João Pessoa – Paraíba, e-mail do setor jurídico pbconsig@codata.pb.gov.br, doravante denominada Controladora, realize o tratamento dos dados pessoais especificados na tabela 1 - Tratamento de Dados Pessoais - com a finalidade de cadastrar o titular como usuário externo em sistema informatizado da CODATA para acesso e assinatura eletrônica em autorização de acesso aos dados e margens para empréstimos, novas averbações, reserva de cartão de crédito e adiantamento,  renegociação e compra de dividas (portabilidade) pelos consignatários indicados, pelo período de vigência da temporalidade do processo administrativo.

          4. Dados coletados

          Nome completo do titular, Documento de Identidade RG, CPF, Matrícula, Vínculo, Órgão, Data de admissão, Data de afastamento, Munícpio lotação, Salário, Situação (exonerado, afastado, ativo, falecido), E-mail, Celular, Data de nascimento, Sexo, Estado Civil, N° do contrato, Valor Prestação.

          5. Direitos dos Titulares

          O titular terá direito de informação, acesso, correção, anonimização, bloqueio ou eliminação, portabilidade e revogação do seu consentimento. O titular poderá exercer seus direitos enviando um pedido por e-mail para: gpd@codata365.pb.gov.br

          6. Compartilhamento de Dados

          Os dados serão compartilhados com as Consignatárias devidamente credenciadas e autorizadas pela Secretaria de Estado da Administração (SEAD) e CODATA conforme decreto estadual de n° 41.993/2021.

          7. Revogação

          Estou ciente de que, a qualquer momento, posso retirar o consentimento ora fornecido, hipótese em que as atividades desenvolvidas pela CODATA, no âmbito da nossa relação. Declaro e concordo que os meus Dados Pessoais poderão ser armazenados, mesmo após o término do tratamento – inclusive após a revogação do consentimento para cumprimento de obrigação legal ou regulatória pela CODATA.

          Links Importantes

          Link para a LGPD – Lei Geral de Proteção de Dados
          http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2018/Lei/L13709compilado.htm

          Link Tratamento de Dados Pessoais Pelo Poder Público
          https://www.gov.br/anpd/pt-br/documentos-e-publicacoes/guia-poder-publico-anpd-versao-final.pdf

          • Considerando que fui informado sobre como será realizada a coleta e utilização dos Dados, bem como será o tratamento dos Dados pelo PBconsig pela CODATA/PB, declaro o meu consentimento em relação ao tratamento dos Dados para as finalidades aqui propostas.


          CENTRO ADMINISTRATIVO ESTADUAL
          Rua João da Mata, S/N, Jaguaribe - CEP: 58.015-020 - Fone: (83) 3208-4490 / (83) 98658-8328 - JOÃO PESSOA - PARAÍBA

      `,
      consentimentoLgpd: {},
      telefoneContato: '(83) 3208-4490',
      mensagem: `O Governo do Estado através da Secretaria de Estado da Administração
                  comunica que, os servidores estaduais que receberiam o Abono Salarial do
                  PASEP de 2024, referente aos meses de: fevereiro, março, abril e maio
                  passarão a receber a <b>partir do dia 15 de junho/2024 e os demais
                  servidores seguirão o calendário publicado pelo Ministério do Trabalho
                  e Emprego</b>, conforme comunicado através do expediente <b>OFICIO SEI Nº
                   33656/2024/MTE,  da Superintendência Regional do Trabalho
                   e Emprego, datado de 13 de maio de 2024.</b>`,
      showComunicado: window.localStorage.getItem('show_comunicado') === 'true',
    }
  },

  validations() {
    return {
      servidor: {
        email: { required, email },
        celular: { required },
      },
    }
  },

  computed: {
    versao: () => {
      return Config.TAG_GIT
    },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },

    topBarClass() {
      return [
        {
          'layout-topbar-responsive':
            this.staticMenuInactive && this.layoutMode === 'static',
        },
      ]
    },
  },
  watch: {
    showComunicado(novoValor) {
      window.localStorage.setItem('show_comunicado', novoValor)
    },
  },

  created() {
    this.ativarTimerParaAtualizarToken()
    if (this.tokenExpirado()) {
      this.logout()
    }
    this.servidorService = new ServidorService(this.$http)
    this.matriculaService = new MatriculaService(this.$http)
    this.consentimentoLgpdService = new ConsentimentoLgpdService(this.$http)
    this.usuarioService = new UsuarioService(this.$http)
  },

  mounted() {
    this.verificarPermissaoGestaoUsuario()
    this.carregarServidor()
    this.verificarPermissaoGestaoUsuario()
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    carregarMenu(/*servicosList*/) {
      this.matriculaService.getMatriculasUsuarioLogado().then((res) => {
        const sead1or2 = res.some(
          (item) =>
            item.entidadeNome === 'SEAD 1' || item.entidadeNome === 'SEAD 2',
        )

        const outraEntidade = res.some(
          (item) =>
            item.entidadeNome !== 'SEAD 1' && item.entidadeNome !== 'SEAD 2',
        )

        const diretasDisabled = !sead1or2
        const indiretasDisabled = sead1or2 && !outraEntidade

        if (this.isStaff) {
          this.menu = [
            {
              label: '',
              items: [
                {
                  label: 'Dados Pessoais (Diretas)',
                  icon: 'pi pi-fw pi-user',
                  disabled: diretasDisabled,
                  to: { name: 'dados-pessoais' },
                },
                {
                  label: 'Dados Funcionais (Diretas)',
                  icon: 'pi pi-fw pi-bars',
                  disabled: diretasDisabled,
                  to: { name: 'dados-funcionais' },
                },
                {
                  label: 'Dados Cadastrais (Indiretas)',
                  icon: 'pi pi-fw pi-user',
                  disabled: indiretasDisabled,
                  to: { name: 'dados-cadastrais' },
                },
                {
                  label: 'Dependentes (Diretas)',
                  icon: 'pi pi-fw pi-users',
                  disabled: diretasDisabled,
                  to: { name: 'dependentes' },
                },
                {
                  label: 'Histórico Funcional (Diretas)',
                  icon: 'pi pi-fw pi-history',
                  disabled: diretasDisabled,
                  to: { name: 'historico-funcional' },
                },
                {
                  label: 'Ficha Financeira',
                  icon: 'pi pi-fw pi-file',
                  disabled: false,
                  to: { name: 'ficha-financeira' },
                },
                {
                  label: 'Contracheque',
                  icon: 'pi pi-fw pi-id-card',
                  to: { name: 'ContraChequeLista' },
                },
                {
                  label: 'Declaração de Rendimentos',
                  icon: 'pi pi-fw pi-book',
                  to: { name: 'rendimentos' },
                },
                {
                  label: 'Código de Ética',
                  icon: 'fas fa-balance-scale',
                  to: { name: 'codigo-etica' },
                },
                {
                  label: 'Protocolo Digital',
                  icon: 'pi pi-book',
                  to: { name: 'protocolo-digital' },
                },
                {
                  separator: true,
                },
                {
                  label: 'Módulo de Gestão',
                  icon: 'pi pi-link',
                  url: 'https://admin.portaldoservidor.rke.codatahml.pb.gov.br/admin/login/?next=/admin/',
                },
                {
                  separator: true,
                },
              ],
            },
            {
              label: 'PBConsig',
              items: [
                {
                  disabled: true,
                  label: 'PBConsig',
                  icon: 'pi pi-link',
                  url: 'https://servidor.pbconsig.pb.gov.br',
                },
              ],
            },
            // {
            //   label: 'Serviços',
            //   items: servicosList,
            // },
          ]
        } else {
          this.menu = [
            {
              label: '',
              items: [
                {
                  label: 'Dados Pessoais (Diretas)',
                  icon: 'pi pi-fw pi-user',
                  disabled: diretasDisabled,
                  to: { name: 'dados-pessoais' },
                },
                {
                  label: 'Dados Funcionais (Diretas)',
                  icon: 'pi pi-fw pi-bars',
                  disabled: diretasDisabled,
                  to: { name: 'dados-funcionais' },
                },
                {
                  label: 'Dados Cadastrais (Indiretas)',
                  icon: 'pi pi-fw pi-user',
                  disabled: indiretasDisabled,
                  to: { name: 'dados-cadastrais' },
                },
                {
                  label: 'Dependentes (Diretas)',
                  icon: 'pi pi-fw pi-users',
                  disabled: diretasDisabled,
                  to: { name: 'dependentes' },
                },
                {
                  label: 'Histórico Funcional (Diretas)',
                  icon: 'pi pi-fw pi-history',
                  disabled: diretasDisabled,
                  to: { name: 'historico-funcional' },
                },
                {
                  label: 'Ficha Financeira',
                  icon: 'pi pi-fw pi-file',
                  disabled: false,
                  to: { name: 'ficha-financeira' },
                },
                {
                  label: 'Contracheque',
                  icon: 'pi pi-fw pi-id-card',
                  to: { name: 'ContraChequeLista' },
                },
                {
                  label: 'Declaração de Rendimentos',
                  icon: 'pi pi-fw pi-book',
                  to: { name: 'rendimentos' },
                },
                {
                  label: 'Código de Ética',
                  icon: 'fas fa-balance-scale',
                  to: { name: 'codigo-etica' },
                },
                {
                  label: 'Protocolo Digital',
                  icon: 'pi pi-book',
                  to: { name: 'protocolo-digital' },
                },
                {
                  separator: true,
                },
              ],
            },
            {
              label: 'PBConsig',
              items: [
                {
                  disabled: true,
                  label: 'PBConsig',
                  icon: 'pi pi-link',
                  url: 'https://servidor.pbconsig.pb.gov.br',
                },
              ],
            },
          ]
        }
      })
    },

    carregarServidor() {
      this.servidorService
        .getDadosServidor()
        .then((res) => {
          this.servidor = res
          this.carregarMenu()
          this.consentimentoLgpdService
            .quantidadeConsentimentoEmUso(this.servidor.id)
            .then((resp) => {
              this.visible = !resp.data
            })
            .catch((err) => {
              this.$toast.add({
                severity: 'error',
                summary: err.response.data.message,
                life: 10000,
              })
              this.$router.push('/logout')
            })
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },

    salvarServidor() {
      if (this.validate()) {
        this.saving = true
        this.servidorService
          .saveServidor(this.servidor)
          .then((res) => {
            this.servidor = res
            this.saving = false
            this.exibeToast('servidor-success')
          })
          .catch((err) => {
            this.exibeToast('error', err.response.data.message)
            this.saving = false
          })
      }
    },

    validate() {
      this.submitted = true
      this.v$.servidor.$touch()
      if (this.v$.servidor.$invalid) {
        return false
      }
      return true
    },

    exibeToast(tipo, msg) {
      if (tipo === 'servidor-success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Servidor atualizado com sucesso.',
          life: 10000,
        })
      } else if (tipo === 'servidor-error') {
        this.$toast.add({
          severity: msg,
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'email-form-error') {
        this.$toast.add({
          severity: 'error',
          summary:
            'É necessário atualizar o e-mail/telefone para ter acesso ao sistema.',
          life: 10000,
        })
      }
    },

    tempoRestante() {
      return this.$auth.getParsedToken().exp - Date.now() / 1000
    },

    tokenExpirado() {
      return this.tempoRestante() < 0
    },

    logout() {
      clearInterval(this.timer)
      this.$auth.logout()
      window.location.reload()
    },

    ativarTimerParaAtualizarToken() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.tokenExpirado()) {
            this.logout()
          } else if (this.tempoRestante() < 140) {
            this.$auth.refreshToken()
          }
        }, 70000)
      }
    },

    isDesktop() {
      return window.innerWidth >= 992
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },

    onSidebarClick() {
      this.menuClick = true
      this.mobileMenuActive = false
    },

    onMenuToggle() {
      this.menuClick = true
      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
      event.preventDefault()
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },

    consentirLgpd() {
      this.consentimentoLgpd.username = this.servidor.cpf
      this.consentimentoLgpdService.create(this.consentimentoLgpd).then(() => {
        this.visible = false
      })
    },
    fecharComunicado() {
      this.showComunicado = false
    },

    async verificarPermissaoGestaoUsuario() {
      this.usuarioService.usuarioPossuiPermissaoGestao().then((res) => {
        this.isStaff = res
      })
    },
  },
}
</script>

<style>
.logo {
  width: 100%;
  margin: auto;
  display: block;
}

.footer {
  background-color: #071d41;

  z-index: 999;
}

@media print {
  .layout-sidebar,
  .noPrint {
    display: none;
  }
}
</style>
